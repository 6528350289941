export class GetSurveyScreens {
  static readonly type = '[Survey] Get Survey Screens';
  constructor(public tempUid: string) {}
}

export class SetQuestionResponse {
  static readonly type = '[Survey] Set Question Response';
  constructor(
    public response: {
      tempUid: string;
      uid: string;
      questionId: string;
      questionVersion: string;
      questionType: string;
      response: string ;
    }
  ) {}
}

export class SetMultipleQuestionResponse {
  static readonly type = '[Survey] Set Multiple Question Response';
  constructor(
    public response: SetQuestionResponse[]
  ) {}
}
