import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { SurveyState } from './_store/state/survey.state';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SurveyService } from './_services/survey.service';
import { ApiInterceptor } from './_interceptors/api.interceptor';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    NgxsModule.forRoot([SurveyState], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false }),
    AppRoutingModule,
    BrowserAnimationsModule],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    SurveyService],
  bootstrap: [AppComponent],
})
export class AppModule {
}
