import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {filter, tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private googleAnalyticsId: string;
  private renderer2: Renderer2;
  private scriptsLoaded = false;

  constructor(
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
    this.googleAnalyticsId = 'G-YNL65T9TX5';
  }

  init() {
    if (!this.scriptsLoaded) {
      this.insertMainScript();
    }
  }

  trackSinglePageView(event: NavigationEnd) {
    if (this.googleAnalyticsId && this.scriptsLoaded) {
      gtag('config', this.googleAnalyticsId, { page_path: event.urlAfterRedirects });
    }
  }

  trackPageViews() {
    return this.router.events.pipe(
      filter(() => this.scriptsLoaded === true),
      filter((evt: RouterEvent) => evt instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        this.trackSinglePageView(event);
      }),
    );
  }

  private insertMainScript() {
    if (this.googleAnalyticsId) {
      const script: HTMLScriptElement = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.onload = this.insertSecondHalfOfScript.bind(this);
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
      script.text = '';
      this.renderer2.appendChild(this.document.body, script);
    }
  }

  private insertSecondHalfOfScript() {
    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = 'google-analytics-script.js';
    script.text = '';
    this.renderer2.appendChild(this.document.body, script);
    script.onload = () => {
      this.scriptsLoaded = true;
    };
  }
}
