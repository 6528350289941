import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from './_services/google-analytics.service';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  showMenu: boolean;

  constructor(public router: Router, private analytics: GoogleAnalyticsService) {
    /* Remove after state persists on refresh */
    /*window.onunload = () => {
      localStorage.clear();
    };*/
    if (localStorage.getItem('tempUid')) {
      this.showMenu = true;
    }
  }

  ngOnInit() {
    this.analytics.init();
    this.analytics.trackPageViews().subscribe();
  }
}
