import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash-screen/splash-screen.module').then(m => m.SplashScreenPageModule),
  },
  {
    path: 'getting-started',
    loadChildren: () => import('./getting-started-screen/getting-started-screen.module').then(m => m.GettingStartedPageModule),
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey-screen/survey-screen.module').then(m => m.SurveyScreenPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up-screen/sign-up-screen.module').then(m => m.SignUpScreenPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login-screen/login-screen.module').then(m => m.LoginScreenPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard-screen/dashboard-screen.module').then(m => m.DashboardScreenPageModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./account-screen/account-screen.module').then(m => m.AccountScreenPageModule),
  },
  {
    path: 'share',
    loadChildren: () => import('./share-screen/share-screen.module').then(m => m.ShareScreenPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
