import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SurveyService {
  constructor(private httpClient: HttpClient) {
  }

  getSurveyScreens() {
    return this.httpClient.get(`${environment.apiUrl}/screens-sequence/all`);
  }

  setQuestionResponse(data) {
    return this.httpClient.post(`${environment.apiUrl}/user-responses`, data);
  }

  setMultipleQuestionResponse(data) {
    return this.httpClient.post(`${environment.apiUrl}/user-responses/multiple`, data);
  }

  getResultScreenData(uid, id) {
    // eslint-disable-next-line max-len
    return this.httpClient.get(`${environment.apiUrl}/results-screens/${uid}/result/${id}`);
  }
}
