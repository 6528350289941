import { Action, State, StateContext } from '@ngxs/store';
import { SurveyStateModel } from '../../_models/survey-state.model';
import { Injectable } from '@angular/core';
import { SurveyService } from '../../_services/survey.service';
import { GetSurveyScreens, SetMultipleQuestionResponse, SetQuestionResponse } from '../actions/survey.actions';

@State<SurveyStateModel>({
  name: 'survey',
  defaults: {
    categories: [],
    screens: [],
    firstCategoryId: {},
    allResponses: [],
  },
})

@Injectable()
export class SurveyState {
  constructor(private surveyService: SurveyService) {
  }

  @Action(GetSurveyScreens)
  getSurveyScreens(ctx: StateContext<SurveyStateModel>) {
    const state = ctx.getState();
    this.surveyService.getSurveyScreens()
      .subscribe(res => {
        const { screens, firstCategoryId, categories } = res[0];
        localStorage.setItem('surveyData', JSON.stringify(res[0]));
        ctx.setState({
          ...state,
          screens,
          firstCategoryId,
          categories,
        });
      });
  }

  @Action(SetQuestionResponse)
  setQuestionResponse(ctx: StateContext<SurveyStateModel>, response) {
    response = response.response;
    let allResponses = JSON.parse(localStorage.getItem('allResponses'));
    if (allResponses) {
      const checkResponseExists = allResponses.findIndex(res => res.questionId === response.questionId);
      if (checkResponseExists > -1) {
        allResponses[checkResponseExists] = response;
      } else {
        allResponses.push(response);
      }
    } else {
      allResponses = [response];
    }
    localStorage.setItem('allResponses', JSON.stringify(allResponses));
    return this.surveyService.setQuestionResponse(response);
  }

  @Action(SetMultipleQuestionResponse)
  setQuestionResponses(ctx: StateContext<SurveyStateModel>, responses) {
    const newResponses = [];
    responses = responses.response;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      newResponses.push(response);
      let allResponses = JSON.parse(localStorage.getItem('allResponses'));
      if (allResponses) {
        const checkResponseExists = allResponses.findIndex(res => res.questionId === response.questionId);
        if (checkResponseExists > -1) {
          allResponses[checkResponseExists] = response;
        } else {
          allResponses.push(response);
        }
      } else {
        allResponses = [response];
      }
      localStorage.setItem('allResponses', JSON.stringify(allResponses));
    }
    return this.surveyService.setMultipleQuestionResponse(newResponses);
  }
}
