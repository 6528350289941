import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    httpRequest = httpRequest.clone({ headers: httpRequest.headers.append('Content-Type', 'application/json') });
    return next.handle(httpRequest).pipe(retry(2), catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse){
    return throwError(error);
  }
}
